export const themeOptions = {
  palette: {
    type: "dark",
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#f97d46",
    },
    background: {
      default: "#e9ddca",
    },
    warning: {
      main: "#f97d46",
    },
    divider: "#f97d46",
  },
  spacing: 12,
  shape: {
    borderRadius: 0,
  },
};
