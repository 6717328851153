import { Button, Container } from "@mui/material";
import lineupmpfmain from "../../images/assets/Lineup.png";
import lineupsponsors from "../../images/assets/Sponsors@300x.png";
import logoPoster from "../../images/logo/Logo with BG@300x.png";
import logoPosterBottom from "../../images/logo/Event info@300x.png";
import gifMpf from "../../images/logo/MPF 2024 GIF - LANDSCAPE.gif";

function Home() {
  return (
    <div className="home">
      <div className="home-tickets">
        <Container maxWidth="xl">
          <div className="modal-content">
            <img src={logoPoster} alt="logo poster" />

            <div className="line-up-container-image">
              <img src={lineupmpfmain} alt="lineup" />
              <img src={lineupsponsors} alt="lineup" />
              <img src={gifMpf} className="mpf-gif" alt="mpf gif" />
            </div>

            <img src={logoPosterBottom} alt="logo poster" />
          </div>
          <div className="home-tickets-container">
            <Button
              href="https://www.seetickets.com/event/manchester-psych-fest-2025/various-venues/3138183"
              color="secondary"
              size="large"
              variant="contained"
              sx={{
                color: "white",
                marginTop: "20px",
                fontSize: "28px",
                padding: "10px 70px",
                borderColor: "white",
                textAlign: "center",
              }}
              target="_blank"
            >
              Buy Tickets
            </Button>

            <Button
              href="https://merchblade.com/collections/manchester-psych-fest"
              color="secondary"
              size="large"
              variant="contained"
              className="ticket-header-button"
              sx={{
                color: "white",
                marginTop: "20px",
                fontSize: "28px",
                padding: "10px 70px",
                borderColor: "white",
                textAlign: "center",
              }}
              target="_blank"
            >
              Buy Merch
            </Button>
          </div>
        </Container>
      </div>
    </div>
  );
}
export default Home;
