import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { ImageList, ImageListItem, useMediaQuery } from "@mui/material";

const galleryImages = [
  "1.jpg",
  "2.jpg",
  "3.jpg",
  "4.jpg",
  "5.jpg",
  "6.jpg",
  "7.jpg",
  "8.jpg",
  "9.jpg",
  "10.jpg",
  "11.jpg",
  "12.jpg",
  "13.jpg",
  "14.jpg",
  "15.jpg",
  "16.jpg",
  "17.jpg",
  "18.jpg",
  "19.jpg",
  "20.jpg",
  "21.jpg",
  "22.jpg",
  "23.jpg",
  "24.jpg",
  "25.jpg",
  "26.jpg",
  "27.jpg",
  "28.jpg",
  "29.jpg",
  "30.jpg",
  "31.jpg",
  "32.jpg",
  "33.jpg",
  "34.jpg",
  "35.jpg",
  "36.png",
  "37.jpg",
  "38.jpg",
  "39.png",
  "40.png",
  "41.jpg",
  "42.jpg",
  "43.jpg",
  "44.png",
  "45.png",
  "46.png",
  "47.png",
  "48.jpg",
  "49.jpg",
  "50.png",
  "51.jpg",
  "52.jpg",
  "53.jpg",
  "54.jpg",
  "55.jpg",
  "56.jpg",
  "57.jpg",
  "58.jpg",
  "59.JPG",
  "60.jpg",
  "61.jpg",
  "62.jpg",
  "63.jpg",
  "64.png",
  "65.jpg",
  "66.png",
  "67.jpg",
  "68.jpg",
  "69.JPG",
  "70.jpg",
  "71.jpg",
  "72.JPG",
  "73.jpg",
  "74.jpg",
  "75.jpg",
  "76.jpg",
  "77.jpg",
  "78.JPG",
  "79.jpg",
  "80.jpg",
  "81.jpg",
  "82.jpg",
  "83.jpg",
  "84.jpg",
  "85.jpg",
  "86.jpeg",
  "87.jpg",
  "88.jpeg",
  "89.jpg",
  "90.jpg",
  "91.jpg",
  "92.jpg",
  "93.jpeg",
  "94.jpg",
  "95.jpg",
  "96.jpg",
  "97.jpg",
  "98.jpg",
  "99.jpg",
  "100.jpg",
  "101.jpg",
  "102.jpeg",
  "103.jpg",
  "104.jpeg",
  "105.jpg",
  "106.jpg",
  "108.jpg",
  "109.jpg",
  "110.jpg",
  "111.jpg",
  "112.jpg",
  "113.jpg",
  "114.jpg",
  "115.jpg",
  "116.jpg",
  "117.jpg",
  "118.jpeg",
  "119.jpg",
  "120.JPG",
  "121.jpg",
  "122.jpg",
  "123.jpg",
  "124.jpeg",
  "125.jpg",
  "126.jpg",
  "127.jpg",
  "128.jpeg",
  "129.jpeg",
  "130.jpg",
  "131.jpg",
  "132.jpg",
  "133.jpeg",
  "134.jpg",
  "135.jpeg",
  "136.jpeg",
  "137.jpg",
  "138.jpeg",
  "139.jpeg",
  "140.jpg",
  "141.jpg",
  "142.jpg",
  "143.jpg",
  "144.jpg",
  "145.jpg",
  "146.jpg",
  "147.jpg",
  "148.jpg",
  "149.jpg",
  "150.jpg",
];

function Gallery() {
  const matches = useMediaQuery("(min-width:600px)");

  return (
    <Container className="gallery" maxWidth="xl">
      <Box sx={{ flexGrow: 1, display: { xs: "flex" } }}>
        <ImageList variant="masonry" cols={matches ? 3 : 1} gap={8}>
          {galleryImages.map((image, index) => (
            <ImageListItemStyled key={index} image={image} />
          ))}
        </ImageList>
      </Box>
    </Container>
  );
}
export default Gallery;

const ImageListItemStyled = ({ image }) => {
  const imageFile = require(`../../images/gallery/${image}`);

  return (
    <ImageListItem>
      <img
        src={`${imageFile}?w=248&fit=crop&auto=format`}
        srcSet={`${imageFile}?w=248&fit=crop&auto=format&dpr=2 2x`}
        alt="Gallery item"
        loading="lazy"
      />
    </ImageListItem>
  );
};
