import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import LineUpCard from "./line-up-card";

const artists = [
  {
    name: "ANNA ERHARD",
    link: "https://www.annaerhard.com/",
    img: "anna-erhard-pressefotos.jpg",
  },
  {
    name: "BUBBLE TEA AND CIGARETTES",
    link: "https://open.spotify.com/artist/2UMeX51X1prCFgi51RHo9P?si=kHyT5YgfQR61YTz0WeOg5w",
    img: "bubble-tea.jpg",
  },
  {
    name: "CROCODILES",
    link: "https://open.spotify.com/artist/1Z4Erxt5wSXH0gbOsVJUMv?si=A7ZlfIepRFSTX2KDlzxO5A",
    img: "crocodiles.jpg",
  },
  {
    name: "DEADLETTER",
    link: "http://www.deadletter.co.uk/",
    img: "deadletter.jpg",
  },
  {
    name: "FUZZ LIGHTYEAR",
    link: "https://open.spotify.com/artist/24Eklv4Dvhk1c6iXuCFzoL?si=--d91T_TSsaPBp-bPGfI9g",
    img: "fuzz-lightyear.jpg",
  },
  {
    name: "GEORGE CLANTON",
    link: "https://open.spotify.com/artist/1G5v3lpMz7TeoW0yGpRQHr?si=U0gfkkc3TgigPKBFsO-cqA",
    img: "george-clanton.jpg",
  },
  {
    name: "GOAT",
    link: "https://open.spotify.com/artist/6jP9Z7o6WlbYvKUOeO5SbP?si=CBe6JOJZQHuzodKGGp_KIQ",
    img: "goat.jpg",
  },
  {
    name: "GOBLYNS",
    link: "https://open.spotify.com/artist/4FKI84FE62a5BxfrKtB1HF?si=EdDpRm_XSSaXeJhFAGHEmA",
    img: "goblyns.jpg",
  },
  {
    name: "GOJI MOON",
    link: "https://open.spotify.com/album/1tg29zjuMlfqKZdTg9s4zj?si=x-c0gXZjSt-wWyp0LxOkXQ",
    img: "goji-moon.png",
  },
  {
    name: "HELICON",
    link: "https://www.heliconglasgow.com/",
    img: "helicon.jpg",
  },
  {
    name: "HONEYGLAZE",
    link: "https://open.spotify.com/artist/0IJZjR1tj2EudGR8jvxZtM?si=Z7laW59tQ2SazKvEhEE9og",
    img: "honey-glaze.jpg",
  },
  {
    name: "MOJO & THE KITCHEN BROTHERS",
    link: "https://open.spotify.com/artist/0uK9AvSTGcy7LKeEGjVvl4?si=El1lYm5kQyCjZFcPI_sVVA",
    img: "mojo.jpeg",
  },
  {
    name: "KIT SEBASTIAN",
    link: "https://kitsebastian.com/",
    img: "kit-seb.jpg",
  },
  {
    name: "PINS Playing Wild Nights",
    link: "https://open.spotify.com/album/4289lORqLU7Ih7eZFFBZyE?si=pvPZ62NwRZmEoewXinxfxA",
    img: "pins.jpg",
  },
  {
    name: "YASMIN COE",
    link: "https://open.spotify.com/artist/0j4GgXioTvigdt9HuQCrAl?si=Z3rJgej5SrePbpdViYumnw",
    img: "yasmin-coe.jpg",
  },
  {
    name: "YUUF",
    link: "https://open.spotify.com/artist/3VfdXeAhqukWy1TTimSA7v?si=R0HEpN2vReugiad6ExyDlw",
    img: "yuff.jpg",
  },
  {
    name: "Adore",
    link: "https://open.spotify.com/artist/4BwY0FjNdkoWef8kixy54M?si=z9HrABpeTs6Wko945Lkwbg",
    img: "adore.jpeg",
  },
  {
    name: "Adult DVD",
    link: "https://open.spotify.com/artist/1lT3vDbjqz299SxePec6ZG?si=rIS8WaSRSeuDu8PVgDRqIA",
    img: "adultdvd.jpg",
  },
  {
    name: "BLIND YEO",
    link: "https://open.spotify.com/artist/5wf9NEx8BtntEd3HiqLNjd?si=zrv0Gj-ETfW9abFzPU7Qfw",
    img: "blindyeo.jpg",
  },
  {
    name: "CHASTITY BELT",
    link: "https://www.chastitybeltmusic.com/",
    img: "chasbelt.jpg",
  },
  {
    name: "DEARY",
    link: "https://open.spotify.com/artist/5Pir3nnrulz7WMyC9bFhkL?si=AChTkclsTeWfAsMY4yCrCg",
    img: "deary.jpeg",
  },
  {
    name: "DU BLONDE",
    link: "https://open.spotify.com/artist/46MOKAqmDsUILZl3iXyPmw?si=2OiN_PZaQ2aR60AEEQ6WWA",
    img: "dublond.jpg",
  },
  {
    name: "DO NOTHING",
    link: "https://donothingband.com/",
    img: "donothing.jpg",
  },
  {
    name: "FAMOUS",
    link: "https://open.spotify.com/artist/76pvNo3GAo5aeXBZ4jVz0k?si=2HiuD2HtSju5pFy4GTEMLw",
    img: "famous.jpeg",
  },
  {
    name: "Gong",
    link: "https://www.gongband.com/",
    img: "gong.jpg",
  },
  {
    name: "GHOST CAR",
    link: "https://open.spotify.com/artist/0JjQ9wolkyDHtnCGE5G84G?si=-5-qbYANSOeSU0TOR6TgkA",
    img: "ghostcar.jpg",
  },
  {
    name: "KARMA SHEEN",
    link: "https://open.spotify.com/artist/5jKjC91GYS58L8hhnDiC4j?si=JUyxAl-NQ-uBmIqY_oEB7w",
    img: "karmasheen.jpg",
  },
  {
    name: "Le Sécurité",
    link: "https://open.spotify.com/artist/5ijO3JnnKpoAOTU4QaDgdW?si=0m5fOmJaQFydIjvXrzLUnw",
    img: "lesec.jpg",
  },
  {
    name: "NADINE SHAH",
    link: "https://nadineshah.co.uk/",
    img: "nadinesha.jpg",
  },
  {
    name: "PLUS44KALIGULA",
    link: "https://www.instagram.com/plus44kaligula/",
    img: "plus44.jpg",
  },
  {
    name: "RABBITFOOT",
    link: "https://www.instagram.com/rabbitfoottheband/",
    img: "rabbitfoot.webp",
  },
  {
    name: "THE SICK MAN OF EUROPE",
    link: "https://www.instagram.com/thesickmanofeurope_/",
    img: "slickman.jpg",
  },
  {
    name: "TROPICAL FUCK STORM",
    link: "https://open.spotify.com/artist/0mL6LBSQ4bHe6hWq90KzHL",
    img: "tropicalfuck.jpeg",
  },
  {
    name: "WESTSIDE COWBOY",
    link: "https://open.spotify.com/artist/5LfO4rbJarBvHjB34mU9m2?si=77PueSzFSne4WE0utiRwew",
    img: "westside.jpg",
  },
  {
    name: "W. H. LUNG",
    link: "https://www.whlungmusic.com/",
    img: "whlung.jpg",
  },

  {
    name: "The Bug Club",
    link: "https://open.spotify.com/artist/7eiTMbuHYUPjrAMjIhAVDB?si=jXoDoWuBSTiLaF1B_0xAzQ",
    img: "bugclub.jpg",
  },
  {
    name: "Christopher Owens",
    link: "https://open.spotify.com/artist/0ukgSx1gUt5rUihbNh46qy",
    img: "chrisowens.jpeg",
  },
  {
    name: "Cryogeyser",
    link: "https://cryogeyser.com/",
    img: "cryogeyser.jpg",
  },
  {
    name: "Dove Ellis",
    link: "https://www.instagram.com/doveellis_/",
    img: "doveellis.jpg",
  },
  {
    name: "Dutch Interior",
    link: "https://open.spotify.com/artist/7a0nCvxmDN4c68agh8ajMn?si=-Fp09wrYTgGlYFZXNxgIfg",
    img: "ducthint.jpeg",
  },
  {
    name: "Floodlights",
    link: "https://floodlightsband.com/",
    img: "floodlights.jpg",
  },
  {
    name: "Getdown Services",
    link: "https://open.spotify.com/artist/4OTD2AbOu5iBqSWk3NfwG5?si=PKEOmeD6Toyp-hyqoUQtVQ",
    img: "getdownservices.jpg",
  },
  {
    name: "Horse Jumper of Love",
    link: "https://horsejumperoflove.com/#home-section",
    img: "horsejumper.jpg",
  },
  {
    name: "Joshua Idehen",
    link: "https://open.spotify.com/artist/3PubLnxnO5N2Avr3UvG1Vc?si=rVu2863RQPWYWtllJ3HlJQ",
    img: "josh.jpeg",
  },
  {
    name: "LSD and the Search for God",
    link: "https://open.spotify.com/artist/2feOOr1Yjovo67byuxvjZv",
    img: "lsdand.png",
  },
  {
    name: "Mandrake Handshake",
    link: "https://open.spotify.com/artist/3tW2ocpP6a9HqZoWgn45kn?si=SFBB4xpGTuiXsPJSn4At9w",
    img: "mandrake.jpg",
  },
  {
    name: "The Orchestra (For Now)",
    link: "https://open.spotify.com/artist/4M1DlOtwciPLmjRWBWOpFG?si=P95044pAStio7uyOYQY37Q",
    img: "theorch.jpg",
  },
  {
    name: "Sex Week",
    link: "https://open.spotify.com/artist/5bcg41eahXhsa30vaMvv0r",
    img: "sexweek.jpeg",
  },
  {
    name: "Silverwingkiller",
    link: "https://www.instagram.com/slvrwngkllr/?hl=en",
    img: "silverringkiller.jpeg",
  },
  {
    name: "Skloss",
    link: "https://www.sklossduo.com/",
    img: "skloss.jpg",
  },
  {
    name: "Slow Fiction",
    link: "https://slowfictionband.com/",
    img: "slowfiction.png",
  },
  {
    name: "The Telescopes",
    link: "https://open.spotify.com/artist/5MTQzge85m3QSefD2LdS1J?si=MxDh5IOiSHKMPJUY9F_KOQ",
    img: "thetele.jpeg",
  },
  {
    name: "Warmduscher",
    link: "https://open.spotify.com/artist/1CWwXncu9sk7EIdbvqcquR",
    img: "warmd.jpg",
  },
  {
    name: "Woioi",
    link: "https://open.spotify.com/artist/4tOkbI0vH6BdfnK5uhaGzR",
    img: "woioi.jpg",
  },
  {
    name: "Y",
    link: "https://open.spotify.com/artist/3Lj9IhE3QFZJo2N2RcQ7Ih?si=PrhZtBrwSsmmG199hR6kjw",
    img: "y.jpg",
  },
  {
    name: "Yellow Days",
    link: "https://open.spotify.com/artist/3dv4Q4q3LWOnbLJnC6GgTY?si=2Q7x5AtSRdO8CcfT5f4S7A",
    img: "yellowdays.jpg",
  },
  {
    name: "Yīn Yīn",
    link: "https://open.spotify.com/artist/2sLMYvxNmHIYNx6re1WUM2",
    img: "yinyin.jpg",
  },
];

const sortedArtists = artists.sort((a, b) => a.name.localeCompare(b.name));

function LineUp() {
  return (
    <Container className="LineUp" maxWidth="xl">
      <Box sx={{ flexGrow: 1, flexWrap: "wrap", display: { xs: "flex" } }}>
        {sortedArtists.map((artist) => (
          <LineUpCard key={artist.name} artist={artist} />
        ))}
      </Box>
    </Container>
  );
}
export default LineUp;
