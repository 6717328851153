import * as React from "react";
import Container from "@mui/material/Container";
import { Typography } from "@mui/material";

function Accessability() {
  return (
    <Container className="accessibility" maxWidth="xl">
      <div className="accessibility-content">
        <div className="text-section">
          <Typography className="title" variant="h6">
            Albert Hall
          </Typography>
          <Typography className="text">
            The Albert Hall accessibility area is located on the balcony level
            of the venue. This reserved seated area has an excellent view of the
            stage where you will be able to fully enjoy the performance.
          </Typography>
          <Typography className="text">
            Albert Hall provides wheelchair access for customers who may require
            this type of service and assistance during their visit. The
            wheelchair access provided is in the form of a stair climber and
            secure wheelchair area on the balcony seating area of the venue.
            Please note that the wheelchair area only has space for up to 4
            wheelchairs and is reserved on a first come first served basis.
          </Typography>
          <Typography className="text">
            {
              "Guide/assistance dogs are welcome in all our venues. We advise customers who require the assistance of a dog, to fill out our access requirements form which can be located here:"
            }
            <a
              href="https://www.alberthallmanchester.com/info/accessibility/"
              target="_blank"
              rel="noreferrer"
            >
              {" Albert Hall Manchester Accessibility"}
            </a>
          </Typography>
        </div>
        <div className="text-section">
          <Typography className="title" variant="h6">
            O2 Ritz
          </Typography>
          <Typography className="text">
            The O2 Ritz is partly accessible. The ground floor is fully
            accessible but the balcony and basement are not accessible.
            Customers who are ambulant with access needs can go on the balcony
            where there will be stools reserved (limited numbers). Customers who
            have purchased tickets and require accessible facilities should
            please email access@o2ritzmanchester.co.uk and they will do their
            best to accommodate any needs.
          </Typography>
        </div>
        <div className="text-section">
          <Typography className="title" variant="h6">
            Projekts Skatepark
          </Typography>
          <Typography className="text">
            The Skate Park is fully accessible.
          </Typography>
        </div>
        <div className="text-section">
          <Typography className="title" variant="h6">
            Gorilla
          </Typography>
          <Typography className="text">
            Upon request Gorilla can provide ground floor access to the music
            hall via the bar and restaurant. Upstairs in the venue there have 6
            x seats what can be reserved If you have difficulty standing.
          </Typography>
        </div>
        <div className="text-section">
          <Typography className="title" variant="h6">
            Deaf Institute
          </Typography>
          <Typography className="text">
            The music hall where live events take place is situated on the top
            floor and is only accessible by stairs. If customers require
            assistance traveling up and down the stairs or if reserved seating
            would assist those who would find standing difficult, please contact
            the venue info@thedeafinstitute.co.uk and they will be happy to
            help. Please be aware that a lift is not available at this venue.
          </Typography>
        </div>
        <div className="text-section">
          <Typography className="title" variant="h6">
            YES
          </Typography>
          <Typography className="text">
            {
              "Both YES Basement and YES Pink Room are fully accessible to customers using the lift."
            }
          </Typography>
        </div>
        <div className="text-section">
          <Typography className="text">
            Each venue will have limited accessibility space and will determined
            on a first come, first serve basis. We will try to accommodate as
            much as we can but we advise to arrive early for any particular acts
            you would like to see. For any further information
            info@manchesterpsychfest.com.
          </Typography>
        </div>
      </div>
    </Container>
  );
}
export default Accessability;
