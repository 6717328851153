import { Outlet } from "react-router-dom";
import ResponsiveAppBar from "../app-bar/app-bar";
import Footer from "../footer/footer";
import { useLayoutEffect } from "react";

import Cursor from "../../../js/cursor";
import Grid from "../../../js/demo2/grid";
// import { Button, Typography } from "@mui/material";
// import Modal from "react-modal";

// const customStyles = {
//   content: {
//     top: "50%",
//     left: "50%",
//     right: "auto",
//     bottom: "auto",
//     marginRight: "-46%",
//     transform: "translate(-50%, -50%)",
//     border: "none",
//     borderRadius: "30px",
//   },
// };

function Layout() {
  useLayoutEffect(() => {
    new Grid(document.querySelector(".grid"));
    new Cursor(document.querySelector(".cursor"));
  }, []);

  // const [modalIsOpen, setIsOpen] = useState(true);

  // const closeModal = () => {
  //   setIsOpen(false);
  // };

  return (
    <>
      <div className="grid-container">
        <div className="grid">
          <div className="grid__item pos-1">
            <div className="grid__item-img"></div>
          </div>
          <div className="grid__item pos-2">
            <div className="grid__item-img"></div>
          </div>
          <div className="grid__item pos-3">
            <div className="grid__item-img"></div>
          </div>
          <div className="grid__item pos-4">
            <div className="grid__item-img"></div>
          </div>
          <div className="grid__item pos-5">
            <div className="grid__item-img"></div>
          </div>
          <div className="grid__item pos-6">
            <div className="grid__item-img"></div>
          </div>
          <div className="grid__item pos-7">
            <div className="grid__item-img"></div>
          </div>
          <div className="grid__item pos-8">
            <div className="grid__item-img"></div>
          </div>
          <div className="grid__item pos-9">
            <div className="grid__item-img"></div>
          </div>
          <div className="grid__item pos-10">
            <div className="grid__item-img"></div>
          </div>
        </div>
      </div>
      <div className="layout">
        {/* <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Manchester Psych Fest 2024"
        >

        </Modal> */}
        <div className="main-content">

            <ResponsiveAppBar />
            <Outlet />
         
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Layout;
