import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";

export default function LineUpCard({ artist }) {
  const image = artist.img
    ? require(`../../images/lineup/${artist.img}`)
    : null;

  return (
    <Card
      sx={{
        width: { xs: "100%", sm: "48%", md: "23%" },
        margin: { xs: "0 0 20px 0", sm: "1%", md: "1%" },
      }}
      className={`${artist.link ? "line-up-card" : "line-up-card dj-card"}`}
    >
      <CardActionArea href={artist.link || ""} target="_blank">
        {image && (
          <CardMedia
            component="img"
            image={image}
            alt={artist.name}
            className="artist-image"
          />
        )}
        <CardContent className="line-up-text-container">
          <Typography className="line-up-text" gutterBottom>
            {artist.name}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
