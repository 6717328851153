import * as React from "react";
import { Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { Link } from "react-router-dom";

function Info() {
  return (
    <Container className="info" maxWidth="xl">
      <div className="info-content">
        <div className="text-section">
          <Typography className="title" variant="h6">
            MANCHESTER PSYCH FEST 2025 – FAQ / INFO
          </Typography>
        </div>
        <div className="text-section">
          <Typography className="title" variant="h6">
            Where
          </Typography>
          <Typography className="text">
            Multi-venue festival at Manchester Academy, Albert Hall, o2 Ritz,
            Projekts Skate Park, Gorilla, YES, Deaf Institute & The Bread Shed.
          </Typography>
          <Typography className="text">
            Ticket gains access to all venues. ***
          </Typography>
        </div>
        <div className="text-section">
          <Typography className="title" variant="h6">
            When
          </Typography>
          <Typography className="text">Saturday 30th August 2025. </Typography>
        </div>
        <div className="text-section">
          <Typography className="title" variant="h6">
            Live music line-up
          </Typography>
          <Typography className="text">
            {"Click "} <Link to="/line-up">here</Link>{" "}
            {" to visit line-up A-Z page."}
          </Typography>
        </div>
        {/* <div className="text-section">
          <Typography className="title" variant="h6">
            DJs
          </Typography>
          <Typography className="text">
            Andy Votel, Astral Elevator, B-Music Residents, Carlo Viscoe,
            Crocodiles, Halo Maud, Laetitia Sadier, Lounge Lizard, DJ Lozaro, Me
            Gusta, Nightbus Soundsystem, PID.inc, Simone Marie (Primal Scream),
            Sliced Tomatoes & Xariella
          </Typography>
          <Typography className="text">
            {"Click "} <Link to="/line-up">here</Link>{" "}
            {" to visit line-up A-Z page."}
          </Typography>
        </div> */}
        <div className="text-section">
          <Typography className="title" variant="h6">
            Art
          </Typography>
          <Typography className="text">
            Exhibitions, murals and visuals to be announced.
          </Typography>
          {/* <Typography className="text">
            {"Click "} <Link to="/art">here</Link> {" to visit our artists."}
          </Typography> */}
        </div>
        {/* <div className="text-section">
          <Typography className="title" variant="h6">
            Film Screenings
          </Typography>
          <Typography className="text">
            {"Click "} <Link to="/film">here</Link>{" "}
            {" for our pop up cinema listings"}
          </Typography>
        </div> */}
        <div className="text-section">
          <Typography className="title" variant="h6">
            Food stalls
          </Typography>
          <Typography className="text">
            Street food will be available across the festival catering for all
            dietary requirements.
          </Typography>
          {/* <Typography className="text">
            {"Click "} <Link to="/food">here</Link> {" to visit our food page."}
          </Typography> */}
        </div>
        <div className="text-section">
          <Typography className="title" variant="h6">
            Accessibility
          </Typography>
          <Typography className="text">
            {"Click "} <Link to="/accessibility">here</Link>{" "}
            {" for accessibility info."}
          </Typography>
        </div>
        <div className="text-section">
          <Typography className="title" variant="h6">
            Tickets
          </Typography>
          <Typography className="text">
            {" "}
            Limited £45 early bird tickets on sale now.{" "}
          </Typography>
          <Typography className="text">
            20% Discounts available for under 24s and NHS staff.
          </Typography>
          <Typography className="text">
            {"Click "}{" "}
            <Link
              target="_blank"
              to="https://dice.fm/partner/manchester-psych-festival/event/366bg-manchester-psych-fest-2024-31st-aug-various-venues-manchester-manchester-canvas-manchester-tickets?dice_id=1436409&dice_channel=web&dice_tags=organic&dice_campaign=Manchester+Psych+Festival&dice_feature=marketing&_branch_match_id=1187789318244657310&_branch_referrer=H4sIAAAAAAAAA8soKSkottLXz8nMy9ZLyUxO1UvL1fcxMzBINbVMMkpLTQIA40i79yEAAAA%3D"
            >
              here
            </Link>{" "}
            {" to purchase."}
          </Typography>
        </div>
        <div className="text-section">
          <Typography className="title" variant="h6">
            Wristband Collection x Festival Hub
          </Typography>
          <Typography className="text">Info coming soon.</Typography>
          {/* <Typography className="text">
            Full details of our festival hub including art, workshops, Yoga,
            Food, drink + more here.
          </Typography> */}
          {/* <Typography className="text">
            {"Click "} <Link to="/festival-hub">here</Link> {" for more info."}
          </Typography> */}
        </div>
        <div className="text-section">
          <Typography className="title" variant="h6">
            Ages
          </Typography>
          <Typography className="text">
            Ages 14+ (before 11pm) Academy, Albert Hall, o2 Ritz , Projekts
            Skate Park, Gorilla,Deaf Institute, The Bread Shed.
          </Typography>
          <Typography className="text">
            18+ YES, all venues after 11pm.
          </Typography>
        </div>
        <div className="text-section">
          <Typography className="title" variant="h6">
            Getting to the festival
          </Typography>
          <Typography className="text">
            The festival is spread across Albert Hall, o2 Ritz, Gorilla, Canvas,
            YES & Deaf Institute. Your wristband gains access to all stages.***
          </Typography>
          <Typography className="text">
            All venues are accessible by road.
          </Typography>
          <Typography className="text">
            Manchester Oxford Rd Station (0.1 miles)
          </Typography>
          <Typography className="text">
            Closest Tram stop: St Peters Square (0.4 miles)
          </Typography>
          <Typography className="text">
            Manchester Piccadilly Station (0.7 miles)
          </Typography>
          <Typography className="text">
            Manchester Shudehill Bus Station (0.9 miles)
          </Typography>
          <Typography className="text">
            Manchester Victoria Station (1.0 miles)
          </Typography>
          <Typography className="text">Manchester Airport (8 miles)</Typography>
          <Typography className="text">Liverpool Airport (33 miles)</Typography>
        </div>
        <div className="text-section">
          <Typography className="text">* Line-up subject to change</Typography>
          <Typography className="text">** plus booking fees</Typography>
          <Typography className="text">
            *** Venue capacities are based on first come first serve so please
            arrive early for specific acts.
          </Typography>
          <Typography className="text">
            {
              "**** Each venue have their own T&C's, please refer to their websites for detailed info."
            }
          </Typography>
          <Typography className="text">
            E.g. some do not permit back packs or cameras.
          </Typography>
        </div>
        <div className="text-section">
          <Typography className="title" variant="h6">
            Contact
          </Typography>
          <Typography className="text">info@manchesterpsychfest.com</Typography>
        </div>
      </div>
    </Container>
  );
}
export default Info;
