import { ThemeProvider, createTheme } from "@mui/material/styles";
import { themeOptions } from "./theme/theme";
import CssBaseline from "@mui/material/CssBaseline";
import { Routes, Route } from "react-router-dom";
import Layout from "./components/common/layout/layout";
import Home from "./components/home/home";
import NoMatch from "./components/no-match/no-match";
import Gallery from "./components/gallery/gallery";
import Info from "./components/info/info";
import LineUp from "./components/line-up/line-up";
// import Film from "./components/film/film";
// import FestivalHub from "./components/festival-hub/festival-hub";
// import Art from "./components/art/art";
// import Food from "./components/food/food";
import Accessability from "./components/accessibility/accessibility";
// import Sponsorship from "./components/sponsorship/sponsorship";
// import StageTimes from "./components/stage-times/stage-times";

import "./App.css";

import "./fonts/ivy-presto-headline-light.otf";

function App() {
  const theme = createTheme(themeOptions);
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <CssBaseline />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="gallery" element={<Gallery />} />
            <Route path="line-up" element={<LineUp />} />
            <Route path="info" element={<Info />} />
            {/* <Route path="stage-times" element={<StageTimes />} />
            <Route path="art" element={<Art />} />
            <Route path="food" element={<Food />} />
            <Route path="film" element={<Film />} />
            <Route path="festival-hub" element={<FestivalHub />} />
            <Route path="sponsorship" element={<Sponsorship />} /> */}
            <Route path="accessibility" element={<Accessability />} /> 
            <Route path="*" element={<NoMatch />} />
          </Route>
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;
